<template>
  <div class="DashboardReports">
    <div class="row row--align-center row--wrap">
      <!-- REPORT TYPE -->
      <div class="column column--width-auto">
        <label
          class="fc-light fs-12 mb-7 no-select uppercase"
          for="report-type"
        >
          REPORT TYPE
        </label>
        <v-select
          v-model="reportType"
          aria-label="Select input for choosing the type of report"
          :clearable="false"
          id="report-type"
          :options="Object.keys(reportsInfo)"
        />
      </div>

      <!-- DROP DOWN -->
      <transition name="Transition__fade">
        <div
          v-if="reportType && reportsInfo[reportType].dropDown"
          class="column column--width-auto"
        >
          <label
            class="fc-light fs-12 mb-7 no-select uppercase"
            for="report-range"
          >
            {{ reportsInfo[reportType].dropDown.label }}
          </label>
          <v-select
            v-model="selectedDropDown"
            aria-label="Select input for choosing the report range"
            class="DashboardReports__report-range-select"
            :clearable="false"
            id="report-range"
            :options="Object.keys(reportsInfo[reportType].dropDown.options)"
          />
        </div>
      </transition>

      <!-- START DATE -->
      <transition name="Transition__fade">
        <div
          v-if="reportType && reportsInfo[reportType].fields.includes('startDate') && showDateRange"
          class="column column--width-auto"
        >
          <label
            class="fc-light fs-12 mb-7 no-select uppercase"
            for="start-date"
          >
            {{ startDateText }}
          </label>
          <base-date-picker
            v-model="startDate"
            id="start-date"
            :valid="startDateValid"
          />
        </div>
      </transition>

      <!-- END DATE -->
      <transition name="Transition__fade">
        <div
          v-if="reportType && reportsInfo[reportType].fields.includes('endDate') && showDateRange"
          class="column column--width-auto"
        >
          <label
            class="fc-light fs-12 mb-7 no-select uppercase"
            for="end-date"
          >
            {{ endDateValid ? 'END DATE' : instructions.END_DATE_INSTRUCTION }}
          </label>
          <base-date-picker
            v-model="endDate"
            id="end-date"
            :valid="endDateValid"
          />
        </div>
      </transition>

      <!-- CHECKBOXES -->
      <transition name="Transition__fade">
        <div
          class="mb-12"
          v-if="reportType && reportsInfo[reportType].checkbox"
        >
          <base-checkbox
            v-model="checkboxValue"
            :label-for="reportsInfo[reportType].checkbox.label"
            :starting-value="reportsInfo[reportType].checkbox.start"
          >
            {{ reportsInfo[reportType].checkbox.text }}
          </base-checkbox>
        </div>
      </transition>
    </div>

    <hr
      v-if="reportType"
      class="Divider"
    >

    <div class="row row--justify-center">
      <base-button
        v-if="saveButtonDisplay"
        @click="saveReport"
        class="bg-blue fc-white"
        :disabled="buttonDisabled"
      >
        Save Report
      </base-button>
    </div>
  </div>
</template>

<script>
// Packages
import moment from 'moment-timezone'
// Helpers
import { Invoice } from '../../utils/api'
// Components
import BaseButton from '../../components/base-button.vue'
import BaseCheckbox from '../../components/base-checkbox.vue'
import BaseDatePicker from '../../components/base-date-picker.vue'
// Mixins
import { ValidationMixin } from '../../utils/validation-mixin'

export default {
  name: 'DashboardReports',

  components: {
    BaseButton,
    BaseCheckbox,
    BaseDatePicker
  },

  mixins: [ValidationMixin],

  data () {
    return {
      buttonDisabled: false,
      checkboxValue: false,
      endDate: null,
      endDateValid: true,
      // avoid having to write growing if/else if logic in download
      reportsInfo: {
        'Batch Report': {
          dropDown: {
            label: 'Transaction Type',
            options: {
              Check: {
                transaction_type: 'lockbox'
              },
              ACH: {
                transaction_type: 'ach'
              },
              Both: {
                transaction_type: 'both'
              }
            },
            showDate: true
          },
          fields: ['startDate'],
          method: Invoice.getBatchReport,
          params: {
            date: 'startDate',
            transaction_type: 'transaction_type'
          }
        },
        'Client Account Summary': {
          fields: ['startDate'],
          method: Invoice.getClientAccountSummaryReport,
          params: {
            date: 'startDate'
          }
        },
        'Client Aging': {
          fields: ['startDate'],
          method: Invoice.getClientAgingGeneralReport,
          params: {
            date: 'startDate'
          }
        },
        'Debtor Aging': {
          checkbox: {
            label: 'showAddress',
            start: false,
            text: 'Include debtor address information'
          },
          fields: ['startDate'],
          method: Invoice.getDebtorAgingGeneralReport,
          params: {
            date: 'startDate',
            show_address: 'checkboxValue'
          }
        },
        'Debtor Payment': {
          fields: ['startDate', 'endDate'],
          method: Invoice.getDebtorPaymentReport,
          params: {
            start: 'startDate',
            end: 'endDate'
          }
        },
        'Detailed Aging': {
          fields: ['startDate'],
          method: Invoice.getDetailedAgingReport,
          params: {
            date: 'startDate'
          }
        },
        'Loan Tape': {
          fields: ['startDate', 'endDate'],
          method: Invoice.getLoanTapeReport,
          params: {
            start: 'startDate',
            end: 'endDate'
          }
        },
        'Portfolio Report': {
          dropDown: {
            label: 'REPORT RANGE',
            options: {
              'All time': {
                start: moment(new Date('1/1/2000')).startOf('day').toDate(),
                end: moment().endOf('day').toDate(),
                title: 'Portfolio Report All Time'
              },
              'Last 3 Months': {
                start: moment().subtract(3, 'months').toDate(),
                end: moment().endOf('day').toDate(),
                title: 'Portfolio Report Last 3 Months'
              },
              'This year': {
                start: moment().startOf('year').toDate(),
                end: moment().endOf('day').toDate(),
                title: 'Portfolio Report This Year'
              },
              'Custom Range': {}
            },
            showCustomOn: 'Custom Range'
          },
          fields: ['startDate', 'endDate'],
          method: Invoice.getPortfolioReport,
          params: {
            start: 'startDate',
            end: 'endDate'
          }
        },
        'Roll Forward': {
          fields: ['startDate', 'endDate'],
          method: Invoice.getRollForwardReport,
          params: {
            start: 'startDate',
            end: 'endDate'
          }
        },
        'Net Funds Employed': {
          fields: ['startDate'],
          method: Invoice.getNetFundsEmployed,
          params: {
            date: 'startDate'
          }
        },
        'Volume Report': {
          fields: ['startDate', 'endDate'],
          method: Invoice.getVolumeReport,
          params: {
            start: 'startDate',
            end: 'endDate'
          }
        },
      },
      reportType: null,
      selectedDropDown: null,
      startDate: null,
      startDateValid: true,
      transaction_type: 'ach'
    }
  },

  watch: {
    reportType () {
      // When reportType changes, reset data
      this.resetData()
    }
  },

  computed: {
    saveButtonDisplay () {
      if (!this.reportType) {
        return false
      }
      return true
    },

    showDateRange () {
      const reportInfo = this.reportsInfo[this.reportType]
      return this.reportType
        && ((!reportInfo.dropDown || reportInfo.dropDown.showDate)
        || this.selectedDropDown === reportInfo.dropDown.showCustomOn)
    },

    startDateText () {
      let text = 'Date'

      // Some reports display an "end date,"" as well, so we want to be
      // more clear to the user
      const reportInfo = this.reportsInfo[this.reportType]
      if (reportInfo.fields.includes('endDate')) {
        text = 'Start Date'

        // The instructions for a "start date" are different than a single "date" report type
        if (!this.startDateValid) {
          text = this.instructions.START_DATE_INSTRUCTION
        }

        return text
      }

      // Generic instructions if date of a single date report type is invalid
      if (!this.startDateValid) {
        text = this.instructions.DATE_INSTRUCTION
      }

      return text
    }
  },

  methods: {
    resetData () {
      // retain the date or default to today
      const resetData = Object.assign(this.$options.data(), {
        endDate: this.startDate || new Date(),
        reportType: this.reportType,
        startDate: this.startDate || new Date()
      })
      Object.assign(this.$data, resetData)
    },

    async saveReport () {
      if (!this.validation()) return

      this.buttonDisabled = true

      try {
        const reportInfo = this.reportsInfo[this.reportType]
        if (!reportInfo) return this.alertWarning('This report is not yet available for download')
        // strip the hours and minutes off the date before passing it back
        this.startDate = moment(this.startDate).startOf('day').toDate()
        this.endDate = moment(this.endDate).startOf('day').toDate()

        // if it's a dropdown, overwrite the start / end dates
        let title = null
        if (reportInfo.dropDown && this.selectedDropDown !== reportInfo.dropDown.showCustomOn) {
          const dropDown = reportInfo.dropDown.options[this.selectedDropDown]
          if (dropDown.start) this.startDate = dropDown.start
          if (dropDown.end) this.endDate = dropDown.end
          if (dropDown.title) title = dropDown.title
          if (dropDown.transaction_type) this.transaction_type = dropDown.transaction_type
        }

        // get the params and call the endpoint specific to the selected report
        const params = {
          title
        }
        Object.keys(reportInfo.params).forEach(key => {
          params[key] = this[reportInfo.params[key]]
        })
        let report
        try {
          report = (await reportInfo.method(params)).data
        }
        catch (error) {
          this.captureSentryEvent(
            'Dashboard Employee Reports "Save Report"',
            {
              config: error.config,
              data: this.$data,
              details: error,
              props: this.$props,
              params,
              response: error.response,
            }
          )
          this.CError(error)
          this.requestFailure({ message: 'there was an issue saving the report' })
        }
        const a = document.createElement('a')
        a.style.display = 'none'
        document.body.appendChild(a)
        a.href = report.url
        a.setAttribute('download', report.file_name)
        a.setAttribute('target', '_blank')
        a.click()
        window.URL.revokeObjectURL(a.href)
        document.body.removeChild(a)
      }
      catch (error) {
        this.CError(error)
        this.requestFailure({ message: 'There was an issue downloading the report' })
      }

      this.buttonDisabled = false
    },

    validation () {
      let valid = true

      if (!this.reportType) return false
      const reportInfo = this.reportsInfo[this.reportType]

      if (reportInfo.fields.includes('startDate')) {
        if (!this.isValidStartDate(this.startDate)) {
          this.startDateValid = false
          valid = false
        } else {
          this.startDateValid = true
        }
      }

      if (reportInfo.fields.includes('endDate')) {
        if (this.endDate) {
          if (!this.startDateValid) {
            valid = false
          } else if (!this.isValidEndDate(this.startDate, this.endDate)) {
            this.endDateValid = false
            valid = false
          } else {
            this.endDateValid = true
          }
        } else {
          this.endDateValid = true
        }
      }
      return valid
    }
  },
}
</script>

<style lang="sass">
.DashboardReports

  .BaseButton
    width: rem(325px)

  .BaseUserSearch,
  .BaseDatePicker,
  .v-select
    margin-bottom: rem(34px)
    margin-right: rem(46px)
    width: rem(300px)

  .Divider
    margin-bottom: rem(50px)

  &__report-range-select
    max-width: rem(200px)
</style>
