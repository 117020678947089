var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DashboardReports" }, [
    _c(
      "div",
      { staticClass: "row row--align-center row--wrap" },
      [
        _c(
          "div",
          { staticClass: "column column--width-auto" },
          [
            _c(
              "label",
              {
                staticClass: "fc-light fs-12 mb-7 no-select uppercase",
                attrs: { for: "report-type" }
              },
              [_vm._v("\n        REPORT TYPE\n      ")]
            ),
            _c("v-select", {
              attrs: {
                "aria-label": "Select input for choosing the type of report",
                clearable: false,
                id: "report-type",
                options: Object.keys(_vm.reportsInfo)
              },
              model: {
                value: _vm.reportType,
                callback: function($$v) {
                  _vm.reportType = $$v
                },
                expression: "reportType"
              }
            })
          ],
          1
        ),
        _c("transition", { attrs: { name: "Transition__fade" } }, [
          _vm.reportType && _vm.reportsInfo[_vm.reportType].dropDown
            ? _c(
                "div",
                { staticClass: "column column--width-auto" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "fc-light fs-12 mb-7 no-select uppercase",
                      attrs: { for: "report-range" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.reportsInfo[_vm.reportType].dropDown.label
                          ) +
                          "\n        "
                      )
                    ]
                  ),
                  _c("v-select", {
                    staticClass: "DashboardReports__report-range-select",
                    attrs: {
                      "aria-label":
                        "Select input for choosing the report range",
                      clearable: false,
                      id: "report-range",
                      options: Object.keys(
                        _vm.reportsInfo[_vm.reportType].dropDown.options
                      )
                    },
                    model: {
                      value: _vm.selectedDropDown,
                      callback: function($$v) {
                        _vm.selectedDropDown = $$v
                      },
                      expression: "selectedDropDown"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]),
        _c("transition", { attrs: { name: "Transition__fade" } }, [
          _vm.reportType &&
          _vm.reportsInfo[_vm.reportType].fields.includes("startDate") &&
          _vm.showDateRange
            ? _c(
                "div",
                { staticClass: "column column--width-auto" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "fc-light fs-12 mb-7 no-select uppercase",
                      attrs: { for: "start-date" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.startDateText) +
                          "\n        "
                      )
                    ]
                  ),
                  _c("base-date-picker", {
                    attrs: { id: "start-date", valid: _vm.startDateValid },
                    model: {
                      value: _vm.startDate,
                      callback: function($$v) {
                        _vm.startDate = $$v
                      },
                      expression: "startDate"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]),
        _c("transition", { attrs: { name: "Transition__fade" } }, [
          _vm.reportType &&
          _vm.reportsInfo[_vm.reportType].fields.includes("endDate") &&
          _vm.showDateRange
            ? _c(
                "div",
                { staticClass: "column column--width-auto" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "fc-light fs-12 mb-7 no-select uppercase",
                      attrs: { for: "end-date" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.endDateValid
                              ? "END DATE"
                              : _vm.instructions.END_DATE_INSTRUCTION
                          ) +
                          "\n        "
                      )
                    ]
                  ),
                  _c("base-date-picker", {
                    attrs: { id: "end-date", valid: _vm.endDateValid },
                    model: {
                      value: _vm.endDate,
                      callback: function($$v) {
                        _vm.endDate = $$v
                      },
                      expression: "endDate"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]),
        _c("transition", { attrs: { name: "Transition__fade" } }, [
          _vm.reportType && _vm.reportsInfo[_vm.reportType].checkbox
            ? _c(
                "div",
                { staticClass: "mb-12" },
                [
                  _c(
                    "base-checkbox",
                    {
                      attrs: {
                        "label-for":
                          _vm.reportsInfo[_vm.reportType].checkbox.label,
                        "starting-value":
                          _vm.reportsInfo[_vm.reportType].checkbox.start
                      },
                      model: {
                        value: _vm.checkboxValue,
                        callback: function($$v) {
                          _vm.checkboxValue = $$v
                        },
                        expression: "checkboxValue"
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.reportsInfo[_vm.reportType].checkbox.text
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ])
      ],
      1
    ),
    _vm.reportType ? _c("hr", { staticClass: "Divider" }) : _vm._e(),
    _c(
      "div",
      { staticClass: "row row--justify-center" },
      [
        _vm.saveButtonDisplay
          ? _c(
              "base-button",
              {
                staticClass: "bg-blue fc-white",
                attrs: { disabled: _vm.buttonDisabled },
                on: { click: _vm.saveReport }
              },
              [_vm._v("\n      Save Report\n    ")]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }